
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        












































.check-block,
[class*='check-block--'] {
  @extend %border-radius;
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 25px, m: 45px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 25px, m: 45px));

  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 48rem;
  background: $c-white;
  border: 1px solid $c-gray-lighter;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }

  &[class*='--large'] {
    max-width: 55.5rem;
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.content {
  display: flex;
  align-items: center;
  // margin-right: 3rem;

  [class*='check-block--'][class*='--reverse'] & {
    margin-right: 0;
    margin-left: 3rem;
  }
}

.content__type {
  max-width: 4rem;
  max-height: 4rem;
  margin-right: 0.5rem;

  &.gaz,
  &.icons-gaz {
    fill: $c-main-secondary;
  }

  &.electricity,
  &.icons-electricity {
    fill: $c-orange;
  }
}

.content__icon {
  max-width: 4rem;
  max-height: 4rem;
  margin-right: 1.1rem;

  &.icons-day,
  &.icons-night {
    fill: $c-gray;
  }
}

.content__copy__title {
  @extend %fw-bold;

  font-family: $ff-default;

  [class*='check-block--'][class*='--small-title'] & {
    // prettier-ignore
    @include fluid(font-size, (xxs: 15px, m: 18px));
  }

  [class*='check-block--'][class*='--tiny-title'] & {
    // prettier-ignore
    @include fluid(font-size, (xxs: 13px, m: 15px));
  }
}

.content__copy__text {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));
  color: $c-gray-darker;
}

.check {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  background-color: $c-gray-lighter;
  border-radius: 50%;
  transition: background-color 0.3s;

  .icon {
    display: none;
    width: 100%;
    height: 100%;
  }

  .is-active & {
    background-color: $c-orange;

    .icon {
      display: block;
      fill: $c-white;
    }
  }
}
