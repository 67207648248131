
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























.files-upload .vue-file-agent.file-input-wrapper {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 5%);
  padding: 15px;
}
